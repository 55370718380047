<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
        style="background-color: #f4ae9e"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <div class="w-100">
          <b-col
            sm="4"
            md="4"
            lg="8"
            class="px-xl-5 mx-auto p-lg-8"
            style="padding-bottom: 4rem !important"
          >
            <img src="@/assets/images/logo/logo.png" alt="logo" />
          </b-col>

          <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
            <b-card-text class="mb-2">Please enter your email address and enter your new password.</b-card-text>

            <validation-observer ref="loginForm" #default="{ invalid }">
              <b-form class="auth-login-form mt-2" @submit.prevent="login">
                <b-form-group label="Email" label-for="login-email">
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="login-email"
                      v-model="request.email"
                      :state="errors.length > 0 ? false : null"
                      name="login-email"
                      placeholder="johndoe@mail.com"
                    />
                    <small class="text-danger"></small>
                  </validation-provider>
                </b-form-group>
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">Token</label>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Token"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="request.token"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="Parola"
                        disabled
                      />

                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>

                    <small class="text-danger"></small>
                  </validation-provider>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">New Password</label>
                  </div>
                  <validation-provider #default="{ errors }" name="Token">
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="request.password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        name="login-password"
                        placeholder="Parola"
                      />

                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>

                    <small class="text-danger"></small>
                  </validation-provider>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">Password Again</label>
                  </div>
                  <validation-provider #default="{ errors }" name="Password">
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="request.confirmPassword"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        name="login-password"
                        placeholder="Parola"
                      />

                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>

                    <small class="text-danger"></small>
                  </validation-provider>
                </b-form-group>

                <b-button
                  @click="resetPassword()"
                  variant="danger"
                  style="margin-top:50px"
                  block
                >{{ $t('ParolaKayıt')}}</b-button>
              </b-form>
            </validation-observer>
          </b-col>
        </div>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/login.png"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      token: "",
      request: {
        token: "1234567890",
        password: "",
        confirmPassword: "",
        email: ""
      }
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    }
  },
  methods: {
    async resetPassword() {
      this.show = true;
      this.$http
        .post("v1/Account/resetPassword", this.request)
        .then(data => {
          this.$toast({
            component: ToastificationContent,
            position: "bottom-right",
            props: {
              title: "Transaction Successful",
              icon: "CoffeeIcon",
              variant: "success",
              text: "Your password has been successfully updated."
            }
          });
          this.show = false;
          this.$router.push("/login");
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: "bottom-right",
            props: {
              title: this.$t("Hatalı"),
              icon: "DangerIcon",
              variant: "danger",
              text: error.response.data.Message
            }
          });
        });
    }
  },
  mounted() {
    this.request.token = this.$route.params.code;
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>